var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-index-layout',{attrs:{"title":_vm.$tc('workOrder.name', 2)},scopedSlots:_vm._u([{key:"new-button",fn:function(){return [_c('base-new-button',{staticClass:"mx-4",attrs:{"to":{ path: 'new' },"append":""}})]},proxy:true}])},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-spacer'),_c('base-field-input',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.taskGroups,"loading":_vm.isLoading,"loader-height":"1","items-per-page":50,"sort-by":"attributes.createdAt","footer-props":{
          itemsPerPageOptions: [25, 50, 75, 100, -1]
        }},scopedSlots:_vm._u([{key:`item.attributes.progress`,fn:function({ item }){return [_c('v-progress-linear',{attrs:{"value":item.attributes.progress,"color":item.attributes.progress === 100 ? 'success' : 'warning'}})]}},{key:`item.attributes.saleOrderName`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.saleOrderName)+" "),_c('base-status-chip',{attrs:{"x-small":"","status":item.attributes.saleOrderStatus}})]}},{key:`item.attributes.deliveredTo.estimatedUnloadDateTo`,fn:function({
            item
          }){return [(item.attributes.deliveredTo.estimatedUnloadDateTo)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.attributes.deliveredTo.estimatedUnloadDateTo,'MMM Do YYYY')))]):_c('span',[_vm._v(_vm._s(_vm.$t('common.notAvailable')))])]}},{key:`item.id`,fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"taskGroup","item":item,"to-show":{
              name: 'task-group-show',
              params: { taskGroupId: item.id }
            },"to-edit":{
              name: 'task-group-edit',
              params: {
                taskGroupId: item.id
              }
            }}})]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }