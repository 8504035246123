<template>
  <div>
    <base-index-layout :title="$tc('workOrder.name', 2)">
      <template #new-button>
        <base-new-button class="mx-4" :to="{ path: 'new' }" append />
      </template>

      <v-card outlined>
        <v-card-title>
          <v-spacer></v-spacer>
          <base-field-input
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></base-field-input>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-data-table
          :search="search"
          :headers="headers"
          :items="taskGroups"
          :loading="isLoading"
          loader-height="1"
          :items-per-page="50"
          sort-by="attributes.createdAt"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 75, 100, -1]
          }"
        >
          <template v-slot:[`item.attributes.progress`]="{ item }">
            <v-progress-linear
              :value="item.attributes.progress"
              :color="item.attributes.progress === 100 ? 'success' : 'warning'"
            >
            </v-progress-linear>
          </template>
          <template v-slot:[`item.attributes.saleOrderName`]="{ item }">
            {{ item.attributes.saleOrderName }}
            <base-status-chip
              x-small
              :status="item.attributes.saleOrderStatus"
            />
          </template>
          <template
            v-slot:[`item.attributes.deliveredTo.estimatedUnloadDateTo`]="{
              item
            }"
          >
            <span v-if="item.attributes.deliveredTo.estimatedUnloadDateTo">{{
              item.attributes.deliveredTo.estimatedUnloadDateTo
                | moment('MMM Do YYYY')
            }}</span>
            <span v-else>{{ $t('common.notAvailable') }}</span>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <BaseTableActions
              module-name="taskGroup"
              :item="item"
              :to-show="{
                name: 'task-group-show',
                params: { taskGroupId: item.id }
              }"
              :to-edit="{
                name: 'task-group-edit',
                params: {
                  taskGroupId: item.id
                }
              }"
            />
          </template>
        </v-data-table>
      </v-card>
    </base-index-layout>
  </div>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
import BaseFieldInput from '@/components/fields/BaseFieldInput.vue'
export default {
  components: {
    BaseFieldInput
  },
  data() {
    return {
      search: '',
      isLoading: true
    }
  },
  computed: {
    headers() {
      return this.createTableHeaders()
    },
    taskGroups() {
      return this.$store.getters['taskGroup/taskGroups']
    }
  },

  async created() {
    this.isLoading = true
    await this.$store.dispatch('taskGroup/getMappedTaskGroups', {
      organizationId: ORGANIZATION_ID()
    })
    this.isLoading = false
  },

  methods: {
    createTableHeaders() {
      return [
        {
          text: this.$tc('workOrder.name', 1),
          align: 'start',
          sortable: false,
          value: 'attributes.title'
        },
        {
          text: this.$tc('sale.name', 1),
          value: 'attributes.saleOrderName'
        },
        {
          text: this.$tc('user.customer', 1),
          value: 'attributes.deliveredTo.companyName'
        },
        {
          text: this.$t('load.destination'),
          value: 'attributes.deliveredTo.location.value'
        },
        {
          text: this.$t('load.etd'),
          value: 'attributes.deliveredTo.estimatedUnloadDateTo'
        },
        {
          text: this.$t('common.progress'),
          value: 'attributes.progress'
        },
        {
          text: this.$tc('purchase.purchaseOrder', 2),
          value: 'attributes.purchaseOrders'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
